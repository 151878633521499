import React, { useLayoutEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { MIN_STRING_DISTANCE } from "@/app/constants.mjs";
import Price from "@/game-fortnite/components/ItemPrice.jsx";
import Reactions from "@/game-fortnite/components/Reactions.jsx";
import {
  SelectItemCategory,
  SelectRarity,
} from "@/game-fortnite/components/Selects.jsx";
import {
  FiltersContainer,
  ItemShopEntryCss,
  LayoutEntryCSS,
  PageHeaderCSS,
  ShopLoadingCSS,
  TimerCSS,
} from "@/game-fortnite/components/Shop.style.jsx";
import {
  ITEM_CATEGORIES_SYMBOLS,
  SHOP_ITEM_CATEGORIES as ITEM_CATEGORIES,
} from "@/game-fortnite/constants/itemCategories.mjs";
import Rarities, {
  RARITY_SYMBOLS,
} from "@/game-fortnite/constants/rarities.mjs";
import type {
  Item,
  ItemLayout,
  ItemShopEntry,
} from "@/game-fortnite/models/item-shop.mjs";
import { offerIdToPathParam } from "@/game-fortnite/utils/transform-offerid.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { classNames } from "@/util/class-names.mjs";
import { formatDateTime, getLocale } from "@/util/i18n-helper.mjs";
import { useQuery } from "@/util/router-hooks.mjs";
import setImageSizeInURL from "@/util/set-image-size-in-url.mjs";
import stringCompare from "@/util/string-compare.mjs";
import usePrevScrollPosition from "@/util/use-prev-scroll-position.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type Layouts = Record<string, { layout: ItemLayout; entries: ItemShopEntry[] }>;

const Filters = ({
  searchText,
  setSearch,
  rarityFilter,
  setRarityFilter,
  category,
  setCategory,
}: {
  searchText: string;
  setSearch: (v: string) => void;
  rarityFilter: string;
  setRarityFilter: (v: string) => void;
  category: string;
  setCategory: (v: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        classNames("flex wrap gap-2 align-center", FiltersContainer()).className
      }
    >
      <div className="flex row gap-2">
        <TextInput
          defaultValue={searchText}
          placeholder={t("common:search.search", "Search")}
          onValueChange={setSearch}
          Icon={SearchIcon}
        />
        <SelectRarity
          rarityFilter={rarityFilter}
          setRarityFilter={setRarityFilter}
        />
      </div>
      <SelectItemCategory
        category={category}
        setCategory={setCategory}
        categories={ITEM_CATEGORIES}
      />
    </div>
  );
};

const ItemShopEntryComp = ({ entry }: { entry: ItemShopEntry }) => {
  const renderImage = entry.newDisplayAsset?.renderImages?.[0];
  const isNew =
    entry.banner?.backendValue?.toLowerCase() ===
    ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.new].key;

  const entryName = entry.bundle?.name || entry.brItems?.[0].name;

  if (!renderImage || !renderImage?.image || !entry.brItems) return null;
  return (
    <div
      className={
        classNames(
          "flex column gap-2",
          ItemShopEntryCss(
            entry?.colors?.color1 || "",
            entry?.colors?.color2 || "",
          ),
        ).className
      }
      id={entry.offerId}
    >
      <a
        className="img-container"
        href={`/fortnite/shop/${offerIdToPathParam(entry.offerId)}`}
      >
        <img
          width={40}
          height={40}
          src={
            renderImage?.image
              ? setImageSizeInURL(renderImage?.image, 200)
              : undefined
          }
          className="offer-image"
          alt={entryName}
          loading="lazy"
        />
        <div className="item-details">
          <p className="type-title--bold item-title">{entryName}</p>
          <Price
            className="price"
            price={entry.finalPrice}
            bannerValue={isNew ? entry.banner?.value : undefined}
            intensity={isNew ? entry.banner?.intensity : undefined}
          />
        </div>
      </a>
      <Reactions
        id={entry.brItems?.length > 1 ? entry.offerId : entry.brItems?.[0]?.id}
      />
    </div>
  );
};

function millisTillMidnight(): number {
  const date = new Date();
  date.setUTCHours(24, 0, 0, 0);
  const currentDate = new Date();
  return date.getTime() - currentDate.getTime();
}

function RefreshTimer() {
  const [seconds, setSeconds] = useState<Date>(new Date(millisTillMidnight()));
  useLayoutEffect(() => {
    let timer = setTimeout(function repeat() {
      setSeconds(new Date(millisTillMidnight()));
      timer = setTimeout(repeat, 1000);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Trans
      i18nKey="fortnite:shop.refreshesIn"
      values={{
        hours: seconds.getUTCHours(),
        minutes: seconds.getUTCMinutes(),
        seconds: seconds.getUTCSeconds(),
      }}
      components={{ highight: <span /> }}
    >
      {`Refreshes in <highight>{{hours}}h {{minutes}}m {{seconds}}s</highight>`}
    </Trans>
  );
}

function Title() {
  const { t } = useTranslation();
  return <span>{t("fortnite:meta.shop.title", "Fortnite Item Shop")}</span>;
}

function ShopLoading() {
  return (
    <div
      className={classNames("flex column gap-4", ShopLoadingCSS()).className}
    >
      <div className="layout-title loading-row" />
      <div className="flex row gap-4">
        <div className="flex column wrap gap-2">
          <div className="img-container loading-row" />
          <div className="title loading-row" />
        </div>
        <div className="flex column wrap gap-2">
          <div className="img-container loading-row" />
          <div className="title loading-row" />
        </div>
      </div>
    </div>
  );
}

function Shop() {
  const { t } = useTranslation();
  const {
    fortnite: { itemShop },
  } = useSnapshot(readState);
  const [searchText, setSearch] = useQuery<string>("q", "");
  const [category, setCategory] = useQuery<string>(
    "category",
    ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.fortniteAllItems].key,
  );
  const [rarityFilter, setRarityFilter] = useQuery<string>(
    "rarity",
    Rarities[RARITY_SYMBOLS.fortniteAllRarities].key,
  );
  const selectedRarity = useMemo(() => {
    const raritySymbol = Object.getOwnPropertySymbols(Rarities).find(
      (r) => Rarities[r].key === rarityFilter,
    );
    return raritySymbol ? Rarities[raritySymbol] : undefined;
  }, [rarityFilter]);
  const layouts: Layouts = useMemo(() => {
    const filteredEntries: ItemShopEntry[] = (itemShop?.entries
      ?.filter((entry) => {
        const name = entry.bundle?.name || entry.brItems?.[0].name || "";
        return searchText
          ? stringCompare(searchText, name) > MIN_STRING_DISTANCE
          : true;
      })
      .filter((entry) => {
        return category === ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.new].key
          ? entry.banner?.backendValue?.toLowerCase() ===
              ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.new].key
          : true;
      })
      .filter((entry) => {
        return entry.newDisplayAsset?.renderImages?.[0]?.image;
      }) || []) as ItemShopEntry[];
    const filters = [
      category &&
        category !==
          ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.fortniteAllItems].key &&
        category !== ITEM_CATEGORIES[ITEM_CATEGORIES_SYMBOLS.new].key &&
        ((item: Item) =>
          item.type.value.toLowerCase() === category.toLowerCase()),
      rarityFilter &&
        rarityFilter !== Rarities[RARITY_SYMBOLS.fortniteAllRarities].key &&
        ((item: Item) =>
          item.rarity.backendValue === selectedRarity?.gameValue),
    ];
    const layouts: Layouts = filteredEntries.reduce((acc, entry) => {
      let filteredItems: Item[] = entry.brItems as Item[];
      filters.forEach((filter) => {
        if (!filter) return;
        filteredItems = filteredItems?.filter(filter);
      });
      if (!filteredItems || filteredItems.length === 0) return acc;
      if (!acc[entry.layout.id]) {
        acc[entry.layout.id] = { layout: entry.layout, entries: [] };
      }
      acc[entry.layout.id].entries.push({
        ...entry,
        items: filteredItems,
      } as ItemShopEntry);
      return acc;
    }, {} as Layouts);
    return layouts;
  }, [category, itemShop, rarityFilter, searchText, selectedRarity]);

  const scrollKey = useMemo(
    () =>
      btoa(
        new URLSearchParams({ searchText, category, rarityFilter }).toString(),
      ),
    [category, rarityFilter, searchText],
  );

  usePrevScrollPosition(scrollKey, !!layouts);

  return (
    <PageContainer>
      <PageHeader title={Title} className={PageHeaderCSS()} />
      <div className={classNames("flex align-center", TimerCSS()).className}>
        <p className="type-title--bold">
          {formatDateTime(getLocale(), new Date(), {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </p>
        <p className="type-title--bold">
          <RefreshTimer />
        </p>
      </div>
      <Filters
        searchText={searchText}
        setSearch={setSearch}
        rarityFilter={rarityFilter}
        setRarityFilter={setRarityFilter}
        category={category}
        setCategory={setCategory}
      />
      {!itemShop?.entries ? <ShopLoading /> : null}
      {Object.values(layouts).length === 0 && itemShop?.entries ? (
        <p className="type-title text-center">
          {t("fortnite:shop.noItemsFound", "No items found")}
        </p>
      ) : null}
      {Object.values(layouts).map((layout) => {
        return (
          <div
            key={layout.layout.id}
            className={
              classNames("flex column gap-4", LayoutEntryCSS()).className
            }
          >
            <h2 className="type-title--bold title">{layout.layout.name}</h2>
            <div className="items">
              {layout.entries.map((entry, index) => (
                <ItemShopEntryComp
                  key={`${entry.layout.id}-${index}`}
                  entry={entry as ItemShopEntry}
                />
              ))}
            </div>
          </div>
        );
      })}
    </PageContainer>
  );
}

export function meta() {
  return {
    title: [
      "fortnite:meta.shop.todaysItemShop",
      "Today's Current Fortnite Item Shop",
    ],
    description: [
      "fortnite:meta.shop.description",
      "Stay ahead of the game with our Fortnite Item Shop tracker, showcasing the latest skins, emotes, and gear for sale. Discover today's hottest items and never miss a beat in Fortnite's ever-changing store.",
    ],
    subtitle: true,
  };
}

export default Shop;
