import { useEffect, useRef, useState } from "react";

import { readState } from "@/__main__/app-state.mjs";
import { setVolatileKV } from "@/app/actions.mjs";
import { APP_SCROLLER } from "@/app/constants.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

/**
 * Restore scroll position to its previous position on page load
 *
 * @param key Key to use for storing scroll position
 * @param isPageLoaded Set this correctly to ensure that previous scroll position is restored only after full page load
 */
function usePrevScrollPosition(key: string, isPageLoaded: boolean) {
  const { volatile } = useSnapshot(readState);
  const prevScrollPosition = volatile[key];
  const scrollPosition = useRef(0);
  const [isSet, setIsSet] = useState<boolean>(false);

  useEffect(() => {
    if (!isPageLoaded || isSet) return;
    if (prevScrollPosition) {
      const [scroller] = globals.document.getElementsByClassName(APP_SCROLLER);
      scroller.scrollTo({ top: prevScrollPosition });
    }
    setVolatileKV(key, undefined);
    setIsSet(true);
  }, [isSet, key, isPageLoaded, prevScrollPosition]);

  useEffect(() => {
    const [scroller] = globals.document.getElementsByClassName(APP_SCROLLER);
    function scroll() {
      scrollPosition.current = scroller.scrollTop;
    }
    scroller.addEventListener("scroll", scroll);
    return () => {
      setVolatileKV(key, scrollPosition.current);
      scroller.removeEventListener("scroll", scroll);
    };
  }, [key]);
}

export default usePrevScrollPosition;
